import { useForm } from 'react-hook-form';

import {
  Box,
  Icon,
  Modal,
  Spinner,
  Tooltip,
  Typography,
} from '@hero-design/react';

import { addNotification } from '@shared/utils';
import {
  TRACK_NOTIFICATION_SETTINGS_TURN_OFF_EMAIL,
  TRACK_NOTIFICATION_SETTINGS_TURN_OFF_MOBILE,
  useMixpanelTracking,
} from '@shared/trackTools/mixpanel';

import useGetCandidateNotificationSettings from 'src/modules/notifications/hooks/useGetCandidateNotificationSettings';
import useUpdateCandidateNotificationSettings from 'src/modules/notifications/hooks/useUpdateCandidateNotificationSettings';

import NotificationSettingsForm, {
  TNotificationSettingsForm,
} from './NotificationSettingsForm';

export type NotificationSettingsModalProps = {
  onClose: () => void;
};

const TooltipContent = () => (
  <Box style={{ maxWidth: 270 }}>
    <Typography.Text intent="white">
      A notification on your mobile can be either a push notification (if you
      are logged into the Employment Hero app) or SMS notification.
    </Typography.Text>
  </Box>
);

const NotificationSettingsModal = ({
  onClose,
}: NotificationSettingsModalProps) => {
  const methods = useForm<TNotificationSettingsForm>();
  const { track } = useMixpanelTracking();

  const {
    refresh: getCandidateNotificationSettings,
    loading: isFetchingNotificationSettings,
  } = useGetCandidateNotificationSettings({
    onCompleted: response => {
      const responseSettings =
        response?.data?.delivery_settings.ats_direct_message || [];

      methods.reset({ newMessageSettings: responseSettings });
    },
    onFailed: () => {
      addNotification({
        intent: 'danger',
        content: 'Something went wrong. Please try again.',
        title: 'Fail',
      });
    },
  });

  const {
    fetcher: updateCandidateNotificationSettings,
    loading: isUpdateNotificationSettings,
  } = useUpdateCandidateNotificationSettings({
    onCompleted: () => {
      getCandidateNotificationSettings();
    },
    onFailed: () => {
      addNotification({
        intent: 'danger',
        content: 'Something went wrong. Please try again.',
        title: 'Fail',
      });
    },
  });

  const handleOnChange = (value: string[]) => {
    updateCandidateNotificationSettings({
      atsDirectMessageSent: value,
    });

    if (!value.includes('email')) {
      track(TRACK_NOTIFICATION_SETTINGS_TURN_OFF_EMAIL, {
        location: 'dashboard',
      });
    }

    if (!value.includes('mobile')) {
      track(TRACK_NOTIFICATION_SETTINGS_TURN_OFF_MOBILE, {
        location: 'dashboard',
      });
    }
  };

  return (
    <Modal
      open
      variant="basic"
      size="large"
      title="Notification settings"
      onClose={onClose}
      body={
        <Spinner
          loading={
            isUpdateNotificationSettings || isFetchingNotificationSettings
          }
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Tooltip
              target={
                <Icon icon="circle-question" data-test-id="mobile-tooltip" />
              }
              content={<TooltipContent />}
              sx={{ mr: 'small', display: 'flex' }}
              placement="right"
              variant="dark"
              data-test-id="notification-setting-tooltip"
            />
            <Typography.Text intent="subdued">
              Manage the type of notifications you receive
            </Typography.Text>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mt: 'medium',
            }}
          >
            <Box>
              <Typography.Text fontWeight="semi-bold">
                New message alerts
              </Typography.Text>

              <Typography.Text sx={{ mt: 'small' }} intent="subdued">
                When a hiring manager sends you a new message
              </Typography.Text>
            </Box>

            <NotificationSettingsForm
              methods={methods}
              handleOnChange={handleOnChange}
            />
          </Box>
        </Spinner>
      }
    />
  );
};

export default NotificationSettingsModal;
